import { Box, Flex, FlexProps, Text } from "@chakra-ui/react";
import React from "react";
import { HiArrowLeft } from "react-icons/hi2";

import { Button, FullscreenHeader, IconButton } from "../../../components";
import MarkdownOutput from "../Recording/SidebarTabs/Ai/Notes/MarkdownOutput";
import { allExamples } from "./examples/allExamples";
import { ExampleTemplate } from "./types";

const pageHeaderHeight = "98px";
const topNavHeight = "48px";

type PickExampleTemplateProps = {
  templateId: string | undefined;
  onClose: (templateId?: string) => void;
  onPickExample: (exampleTemplate: ExampleTemplate) => void;
  onBack: () => void;
};

const PickExampleTemplate: React.FC<PickExampleTemplateProps> = ({
  templateId,
  onClose,
  onPickExample,
  onBack,
}) => {
  const [selectedExample, setSelectedExample] = React.useState<ExampleTemplate>(
    allExamples[0]
  );
  return (
    <Box>
      <FullscreenHeader>
        <Flex height="40px" my={3} flexDir="row" alignItems="center">
          <IconButton
            aria-label="Back"
            icon={<HiArrowLeft size="24" />}
            color="gray.900"
            variant="icon"
            onClick={onBack}
            mr={9}
          />
          <Box fontSize="xl" fontWeight="medium">
            {templateId ? "Edit" : "New"} AI notes template
          </Box>
          <Button
            ml="auto"
            size="prismMd"
            variant="prismTertiary"
            onClick={() => onClose(templateId)}
          >
            Cancel
          </Button>
          <Button
            ml={6}
            size="prismMd"
            variant="prismPrimary"
            onClick={() => onPickExample(selectedExample)}
          >
            Use Template
          </Button>
        </Flex>
        <Box
          height={1}
          background="linear-gradient(99.01deg, #0074DD -2.93%, #591EFF 58.85%, #9644FF 126.18%)"
        />
      </FullscreenHeader>
      <Text fontSize="xl" mt={6}>
        Choose example template
      </Text>
      <BodyWrapper>
        <ColumnWrapper
          flex="1"
          maxWidth={["initial", "initial", "400px", "400px"]}
        >
          <Text fontSize="xs" color="gray.600" fontWeight="semibold" mb={2}>
            TEMPLATE
          </Text>
          <Box
            border="1px solid"
            borderColor="gray.100"
            rounded="md"
            overflow="auto"
          >
            {allExamples.map((example) => (
              <Box
                key={example.name}
                onClick={() => setSelectedExample(example)}
                p={4}
                py={3}
                cursor="pointer"
                borderBottom={
                  allExamples[allExamples.length - 1].name !== example.name
                    ? "1px solid"
                    : "none"
                }
                borderColor="gray.200"
                background={example === selectedExample ? "blue.50" : "inherit"}
                _hover={{
                  background: "blue.50",
                }}
              >
                <Text fontSize="sm" fontWeight="medium">
                  {example.name}
                </Text>
              </Box>
            ))}
          </Box>
        </ColumnWrapper>
        <ColumnWrapper
          flex="2"
          pt={[0, 0, 8, 8]}
          overflowY={["inherit", "inherit", "hidden", "hidden"]}
        >
          <Text fontSize="xs" color="gray.600" fontWeight="semibold" mb={2}>
            SAMPLE OUTPUT
          </Text>
          <Box
            border="1px solid"
            borderColor="gray.100"
            rounded="md"
            py={4}
            px={5}
            overflowY="auto"
          >
            <MarkdownOutput
              markdown={selectedExample.output}
              onClickTimestamp={() => null}
              px={0}
              py={0}
              border="none"
            />
          </Box>
        </ColumnWrapper>
      </BodyWrapper>
    </Box>
  );
};

const BodyWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const height = [
    "auto",
    "auto",
    `calc(100vh - ${pageHeaderHeight} - ${topNavHeight} - 30px)`,
    `calc(100vh - ${pageHeaderHeight} - 30px)`,
  ];

  return (
    <Flex
      overflowY="hidden"
      height={height}
      flex="1"
      flexDir={["column", "column", "row", "row"]}
      pb={[8, 8, 0, 0]}
      gap={4}
    >
      {children}
    </Flex>
  );
};

const ColumnWrapper: React.FC<
  {
    children: React.ReactNode;
  } & FlexProps
> = ({ children, ...flexProps }) => {
  return (
    <Flex
      flexDir="column"
      pt={8}
      pb={4}
      height="100%"
      overflowY="hidden"
      flex="1"
      {...flexProps}
    >
      {children}
    </Flex>
  );
};

export default PickExampleTemplate;
