import { ApolloError } from "@apollo/client";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  Alert,
  Button,
  Link,
  ZoomAppAutoOpenOrgSetting,
} from "../../../components";
import DisclaimerInputs, {
  DisclaimerFormData,
} from "../../../components/DisclaimerInputs/DisclaimerInputs";
import NotetakerDisplayImageSelector from "../../../components/NotetakerDisplayImage/NotetakerDisplayImageSelector";
import {
  CallType,
  Maybe,
  NotetakerRecorderDisclaimerType,
  OrgZoomAppAutoOpenRule,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";

export interface FormData extends DisclaimerFormData {
  enabled: boolean;
  sendCancelRecordingChatMessage: boolean;
  virtualOnsiteEnabled: boolean;
  displayName?: Maybe<string>;
  displayImage?: string;
  displayImageFilename?: string;
  zoomAppAutoOpenRule: OrgZoomAppAutoOpenRule;
}

interface ZoomNotetakerSettingsFormProps {
  organizationName: string;
  zoomNotetakerEnabled: boolean;
  sendCancelRecordingChatMessage: boolean;
  zoomAppAutoOpenRule?: Maybe<OrgZoomAppAutoOpenRule>;
  zoomNotetakerDisclaimer?: Maybe<string>;
  zoomNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
  zoomNotetakerDisplayImageBucketPath?: Maybe<string>;
  defaultDisclaimer: string;
  defaultDisplayName?: Maybe<string>;
  virtualOnsiteEnabled: boolean;
  showVirtualOnsiteSetting: boolean;
  error: ApolloError | undefined;
  isLoading: boolean;
  onSubmit: (formData: FormData) => Promise<void>;
}

const ZoomNotetakerSettingsForm: React.FC<ZoomNotetakerSettingsFormProps> = ({
  organizationName,
  zoomNotetakerEnabled,
  sendCancelRecordingChatMessage,
  zoomAppAutoOpenRule,
  zoomNotetakerDisclaimer,
  zoomNotetakerDisclaimerType,
  zoomNotetakerDisplayImageBucketPath,
  defaultDisclaimer,
  defaultDisplayName,
  virtualOnsiteEnabled,
  showVirtualOnsiteSetting,
  error,
  isLoading,
  onSubmit,
}) => {
  const zoomAppAutoOpenEnabled = useFeatureFlag("zoom-app-auto-open");
  const formMethods = useForm<FormData>({
    defaultValues: {
      enabled: zoomNotetakerEnabled,
      sendCancelRecordingChatMessage,
      zoomAppAutoOpenRule:
        zoomAppAutoOpenRule ?? OrgZoomAppAutoOpenRule.DefaultDisabled,
      disclaimer: zoomNotetakerDisclaimer ?? "",
      disclaimerType: zoomNotetakerDisclaimerType,
      defaultDisclaimer,
      virtualOnsiteEnabled,
      displayName: defaultDisplayName,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const DEFAULT_BH_DISPLAY_IMAGE = "brighthire.jpg";
  const previewImageSrc =
    zoomNotetakerDisplayImageBucketPath ?? DEFAULT_BH_DISPLAY_IMAGE;
  const [displayImageBase64, setDisplayImageBase64] = React.useState<
    string | null
  >(null);

  return (
    <FormProvider {...formMethods}>
      <form
        autoComplete="on"
        onSubmit={handleSubmit(async (formData) => {
          await onSubmit({
            ...formData,
            ...(displayImageBase64 && { displayImage: displayImageBase64 }),
          });
          setDisplayImageBase64(null);
        })}
      >
        {error?.graphQLErrors?.map(({ message }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Alert mb={8} key={i} status="error" description={message} />
        ))}
        {/** Primary fields */}
        <FormControl id="zoomNotetakerEnabled" mb={8}>
          <Checkbox
            {...register("enabled")}
            defaultChecked={zoomNotetakerEnabled}
          >
            Enabled
          </Checkbox>
          <FormHelperText>
            This turns on the Zoom Notetaker for your organization.
          </FormHelperText>
        </FormControl>

        <FormControl id="sendCancelRecordingChatMessage" mb={8}>
          <Checkbox
            {...register("sendCancelRecordingChatMessage")}
            defaultChecked={sendCancelRecordingChatMessage}
          >
            Send Cancel Recording Link
          </Checkbox>
          <FormHelperText>
            When each participants joins, the Notetaker will send them a message
            which contains a link that when clicked will remove the Notetaker
            and delete the recording.
          </FormHelperText>
        </FormControl>

        {zoomAppAutoOpenEnabled && (
          <ZoomAppAutoOpenOrgSetting
            id="zoomAppAutoOpenRule"
            mb="8"
            register={register("zoomAppAutoOpenRule")}
          />
        )}

        <FormControl
          id="displayName"
          mb={8}
          isInvalid={errors.displayName !== undefined}
        >
          <FormLabel fontSize="md">Notetaker display name</FormLabel>
          <Input
            {...register("displayName")}
            placeholder={`${organizationName} Notetaker`}
            data-testid="display-name"
            maxLength={60}
          />
          <FormHelperText>
            The name that will be displayed for the Zoom Notetaker in meetings.
          </FormHelperText>
          {errors.displayName !== undefined && (
            <FormErrorMessage>{errors.displayName.message}</FormErrorMessage>
          )}
        </FormControl>

        <NotetakerDisplayImageSelector
          previewImageSrc={previewImageSrc}
          onResetToDefault={() => {
            handleSubmit(async (formData) => {
              await onSubmit({
                ...formData,
                ...{
                  displayImageFilename: DEFAULT_BH_DISPLAY_IMAGE,
                },
              });
            })();
            setDisplayImageBase64(null);
          }}
          onImageGenerated={setDisplayImageBase64}
          onImageReset={() => setDisplayImageBase64(null)}
          showResetToDefault={
            !previewImageSrc.endsWith(DEFAULT_BH_DISPLAY_IMAGE)
          }
        />

        {/** Disclaimer fields */}
        <DisclaimerInputs callType={CallType.ZoomNotetaker} />

        <Box mb={6} hidden={!zoomNotetakerEnabled || !showVirtualOnsiteSetting}>
          <Divider mb={6} />
          <Heading as="h4" fontSize="md" mb={6}>
            Virtual Onsite
          </Heading>

          <FormControl id="virtualOnsiteEnabled">
            <Checkbox
              {...register("virtualOnsiteEnabled")}
              defaultChecked={virtualOnsiteEnabled}
              data-testid="virtual-onsite-enabled"
            >
              Enabled
            </Checkbox>
            <FormHelperText>
              Use interviewer attendance to automatically create separate
              interview recordings of sequential scheduled interviews that share
              a meeting meeting url. This will also provide interviewers more
              control over joining, starting, stopping the different sections of
              onsite via the Interview Assistant.
            </FormHelperText>
            <FormHelperText>
              <Link
                href="http://help.brighthire.ai/en/articles/6809892-virtual-onsites"
                target="_blank"
              >
                {" Learn More"}
              </Link>
            </FormHelperText>
          </FormControl>
        </Box>

        <Button
          type="submit"
          mt={4}
          isLoading={isLoading}
          data-testid="zoom-notetaker-settings-submit"
        >
          Save
        </Button>
      </form>
    </FormProvider>
  );
};

export default ZoomNotetakerSettingsForm;
