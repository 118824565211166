import React from "react";

import { useFeatureFlagForCurrentOrImpersonatedUser } from "../../graphql/hooks/useFeatureFlag";
import NotFound from "../../pages/not-found/NotFoundPage";

type FeatureGateProps = {
  // Add active feature flags here as enum values to avoid typos
  flag:
    | "ai-recruiter:v0"
    | "analytics:quality-report"
    | "analytics:my-insights"
    | "analytics:topic-trends"
    | "analytics:candidate-motivations"
    | "analytics:candidate-questions"
    | "analytics:skills-report"
    | "custom_templates_v2:launch"
    | "analytics:compensation-trends";
  blacklistFlag?: "custom_templates_v2:blacklist";
  children: JSX.Element;
};

const FeatureGate: React.FC<FeatureGateProps> = ({
  flag,
  blacklistFlag = "",
  children,
}) => {
  const blacklisted =
    useFeatureFlagForCurrentOrImpersonatedUser(blacklistFlag) &&
    blacklistFlag !== "";
  const enabled = useFeatureFlagForCurrentOrImpersonatedUser(flag);
  if (!enabled || blacklisted) {
    return <NotFound />;
  }
  return children;
};

export default FeatureGate;
