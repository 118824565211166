import { Box, Divider, Text, useTheme, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import React, { ReactElement, ReactNode } from "react";
import {
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { getFormatterForCurrency } from "../../../../utils/currency";
import {
  CompensationExtractionCategory,
  CompensationTrendsDataPoint,
} from "../../../graphql";
import { dataVizColorRangesBright } from "../chart/segmentPresentation";
import TooltipContainer from "../line-chart/TooltipContainer";
import TooltipDataRow from "../line-chart/TooltipDataRow";

// Constants for chart configuration
const CHART_CONFIG = {
  jitter: 0.1,
  domainPadding: 0.6, // jitter + 0.5
  referenceLine: {
    width: 0.15,
    color: "#393f47",
    opacity: {
      vertical: 0.8,
      median: 0.8,
      quartile: 0.8,
    },
    strokeWidth: 1.5,
  },
  positions: {
    INTERVIEWER_STATED: 1,
    CANDIDATE_CURRENT: 2,
    CANDIDATE_EXPECTED: 3,
  },
  colors: {
    INTERVIEWER_STATED: dataVizColorRangesBright.blueBolt[200],
    CANDIDATE_CURRENT: dataVizColorRangesBright.aareRiver[200],
    CANDIDATE_EXPECTED: dataVizColorRangesBright.singaporeOrchid[200],
  },
  names: {
    INTERVIEWER_STATED: "Interviewer Stated",
    CANDIDATE_CURRENT: "Candidate Current",
    CANDIDATE_EXPECTED: "Candidate Expected",
  },
} as const;

type CategoryStats = {
  q1: number;
  q3: number;
  median: number;
};

interface ChartProps {
  data: CompensationTrendsDataPoint[];
  currency: string;
}

type DataByCategory = Record<
  CompensationExtractionCategory,
  Array<CompensationTrendsDataPoint & { x: number; isHighlighted: boolean }>
>;

const calculateQuartile = (numbers: number[], quartile: number): number => {
  const sorted = numbers.sort((a, b) => a - b);
  const pos = (sorted.length - 1) * quartile;
  const base = Math.floor(pos);
  const rest = pos - base;

  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  }
  return sorted[base];
};

const CustomTooltip = ({
  active,
  payload,
  dataByCategory,
  currency,
}: {
  active?: boolean;
  payload?: Array<{ payload: CompensationTrendsDataPoint }>;
  dataByCategory: DataByCategory;
  currency: string;
}): ReactElement | null => {
  if (!active || !payload?.[0]?.payload) return null;

  const hoveredPoint = payload[0].payload;
  const relatedPoints = Object.entries(dataByCategory).reduce(
    (acc, [category, points]) => {
      const relatedPoint = points.find((p) => p.callId === hoveredPoint.callId);
      if (relatedPoint) {
        acc[category as CompensationExtractionCategory] = relatedPoint;
      }
      return acc;
    },
    {} as Record<
      CompensationExtractionCategory,
      CompensationTrendsDataPoint & { x: number; isHighlighted: boolean }
    >
  );

  const categoryOrder = Object.keys(
    CHART_CONFIG.positions
  ) as CompensationExtractionCategory[];
  return (
    <TooltipContainer>
      <VStack w="100%">
        <Text w="100%" textAlign="left">
          {hoveredPoint.callName}
        </Text>
        <Divider />
        <TooltipDataRow
          label="Date"
          value={format(new Date(hoveredPoint.startTime), "MMM d, yyyy")}
        />
        {categoryOrder.map((category: CompensationExtractionCategory) => {
          const point = relatedPoints[category];
          if (!point) return null;

          return (
            <TooltipDataRow
              key={category}
              label={CHART_CONFIG.names[category]}
              value={getFormatterForCurrency(currency).format(
                point.formattedValue
              )}
              bulletColor={CHART_CONFIG.colors[category]}
            />
          );
        })}
      </VStack>
    </TooltipContainer>
  );
};

const AnalyticsCompensationTrendsChart: React.FC<ChartProps> = ({
  data,
  currency,
}) => {
  const theme = useTheme();
  const { colors } = theme;
  const [highlightedCallId, setHighlightedCallId] = React.useState<
    string | null
  >(null);

  // Calculate IQR and filter outliers
  const filteredData = React.useMemo(() => {
    const values = data
      .map((point) => point.formattedValue)
      .filter((val) => val != null);

    if (values.length === 0) return [];

    const q1 = calculateQuartile(values, 0.25);
    const q3 = calculateQuartile(values, 0.75);
    const iqr = q3 - q1;
    const lowerBound = q1 - 1.25 * iqr;
    const upperBound = q3 + 1.25 * iqr;

    return data.filter(
      (point) =>
        point.formattedValue != null &&
        point.formattedValue >= lowerBound &&
        point.formattedValue <= upperBound
    );
  }, [data]);

  // Add jitter to data points - only recalculates when filtered data changes
  const jitteredData = React.useMemo(() => {
    return filteredData.map((point) => ({
      ...point,
      x:
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        CHART_CONFIG.positions[point.category] +
        (Math.random() - 0.5) * CHART_CONFIG.jitter,
    }));
  }, [filteredData]);

  // Group by category and add highlight state - recalculates when visibility/highlight changes
  const dataByCategory = React.useMemo(() => {
    const grouped = jitteredData.reduce((acc, point) => {
      if (!acc[point.category]) {
        acc[point.category] = [];
      }
      acc[point.category].push({
        ...point,
        isHighlighted: point.callId === highlightedCallId,
      });
      return acc;
    }, {} as DataByCategory);

    // Sort categories by their position values
    return Object.fromEntries(
      Object.entries(grouped).sort(
        ([a], [b]) =>
          CHART_CONFIG.positions[a as CompensationExtractionCategory] -
          CHART_CONFIG.positions[b as CompensationExtractionCategory]
      )
    ) as DataByCategory;
  }, [jitteredData, highlightedCallId]);

  // Calculate statistics for each category
  const categoryStats = React.useMemo(() => {
    return Object.entries(dataByCategory).reduce((acc, [category, points]) => {
      const values = points
        .map((p) => {
          if (p.exactAmount) return p.exactAmount;
          if (p.minAmount && p.maxAmount)
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return (p.minAmount + p.maxAmount) / 2;
          return p.minAmount || p.maxAmount || 0;
        })
        .filter(Boolean);

      if (values.length) {
        acc[category as CompensationExtractionCategory] = {
          q1: calculateQuartile(values, 0.25),
          q3: calculateQuartile(values, 0.75),
          median: calculateQuartile(values, 0.5),
        };
      }
      return acc;
    }, {} as Record<CompensationExtractionCategory, CategoryStats>);
  }, [dataByCategory]);

  const handlePointClick = (data: CompensationTrendsDataPoint): void => {
    if (data.callId) {
      let url = `/interview/${data.callId}`;
      if (data.firstSegmentStartTime) {
        url += `?t=${data.firstSegmentStartTime}`;
      }
      window.open(url, "_blank");
    }
  };

  const handleMouseEnter = (point: CompensationTrendsDataPoint): void => {
    if (point.callId) {
      setHighlightedCallId(point.callId);
    }
  };

  const handleMouseLeave = (): void => {
    setHighlightedCallId(null);
  };

  const renderLegendText = (value: string): ReactNode => {
    return (
      <Text as="span" color="gray.800" fontSize="xs" pr="4">
        {value}
      </Text>
    );
  };

  return (
    <Box h="400px" onMouseLeave={handleMouseLeave}>
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          margin={{
            top: 20,
            right: 20,
            left: 50,
            bottom: 20,
          }}
          onMouseLeave={handleMouseLeave}
        >
          <XAxis
            type="number"
            dataKey="x"
            domain={[
              1 - CHART_CONFIG.domainPadding,
              3 + CHART_CONFIG.domainPadding,
            ]}
            ticks={[1, 2, 3]}
            tickFormatter={() => {
              return "";
            }}
            axisLine={{ stroke: colors.gray[200] }}
            tick={{ fill: "transparent", stroke: "transparent" }}
            tickLine={{ stroke: colors.gray[200] }}
            fontSize={12}
          />
          <YAxis
            dataKey="formattedValue"
            type="number"
            tickFormatter={(value) =>
              getFormatterForCurrency(currency).format(value)
            }
            fontSize={12}
            axisLine={{ stroke: colors.gray[200] }}
            tick={{ fill: colors.gray[800], textAnchor: "end" }}
            tickLine={{ stroke: colors.gray[200] }}
          />
          <Tooltip
            content={
              <CustomTooltip
                dataByCategory={dataByCategory}
                currency={currency}
              />
            }
          />
          <Legend iconSize={12} formatter={renderLegendText} />
          {Object.entries(dataByCategory).map(([category, points]) => (
            <Scatter
              key={category}
              dataKey={category}
              name={
                CHART_CONFIG.names[category as CompensationExtractionCategory]
              }
              fill={
                CHART_CONFIG.colors[category as CompensationExtractionCategory]
              }
              data={points}
              shape={(props: any) => {
                const { cx, cy } = props;
                const point = props.payload;
                const opacity = point.isHighlighted
                  ? 1
                  : highlightedCallId
                  ? 0.1
                  : 1;
                const r = point.isHighlighted ? 8 : 4;

                return (
                  <circle
                    cx={cx}
                    cy={cy}
                    r={r}
                    fill={
                      CHART_CONFIG.colors[
                        category as CompensationExtractionCategory
                      ]
                    }
                    opacity={opacity}
                    style={{ transition: "all 0.3s ease", cursor: "pointer" }}
                  />
                );
              }}
              onClick={handlePointClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{ cursor: "pointer" }}
              isAnimationActive={false}
            />
          ))}
          {/* Add statistical reference lines */}
          {Object.entries(categoryStats).map(([category, stats]) => {
            const xPos =
              CHART_CONFIG.positions[
                category as CompensationExtractionCategory
              ];

            return (
              <React.Fragment key={`stats-${category}`}>
                {/* Vertical line from Q1 to Q3 */}
                <ReferenceLine
                  segment={[
                    { x: xPos, y: stats.q1 },
                    { x: xPos, y: stats.q3 },
                  ]}
                  stroke={CHART_CONFIG.referenceLine.color}
                  strokeWidth={CHART_CONFIG.referenceLine.strokeWidth}
                  opacity={CHART_CONFIG.referenceLine.opacity.vertical}
                />
                {/* Median line */}
                <ReferenceLine
                  segment={[
                    {
                      x: xPos - CHART_CONFIG.referenceLine.width / 4,
                      y: stats.median,
                    },
                    {
                      x: xPos + CHART_CONFIG.referenceLine.width / 4,
                      y: stats.median,
                    },
                  ]}
                  stroke={CHART_CONFIG.referenceLine.color}
                  strokeWidth={CHART_CONFIG.referenceLine.strokeWidth}
                  opacity={CHART_CONFIG.referenceLine.opacity.median}
                />
                {/* Q1 tick */}
                <ReferenceLine
                  segment={[
                    {
                      x: xPos - CHART_CONFIG.referenceLine.width / 6.5,
                      y: stats.q1,
                    },
                    {
                      x: xPos + CHART_CONFIG.referenceLine.width / 6.5,
                      y: stats.q1,
                    },
                  ]}
                  stroke={CHART_CONFIG.referenceLine.color}
                  strokeWidth={CHART_CONFIG.referenceLine.strokeWidth}
                  opacity={CHART_CONFIG.referenceLine.opacity.quartile}
                />
                {/* Q3 tick */}
                <ReferenceLine
                  segment={[
                    {
                      x: xPos - CHART_CONFIG.referenceLine.width / 6.5,
                      y: stats.q3,
                    },
                    {
                      x: xPos + CHART_CONFIG.referenceLine.width / 6.5,
                      y: stats.q3,
                    },
                  ]}
                  stroke={CHART_CONFIG.referenceLine.color}
                  strokeWidth={CHART_CONFIG.referenceLine.strokeWidth}
                  opacity={CHART_CONFIG.referenceLine.opacity.quartile}
                />
              </React.Fragment>
            );
          })}
        </ScatterChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default AnalyticsCompensationTrendsChart;
