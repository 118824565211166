import {
  ButtonGroup,
  ButtonProps,
  Flex,
  Icon,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React from "react";
import { HiChevronDown } from "react-icons/hi";
import { MdInfo } from "react-icons/md";

import {
  Button,
  errorToast,
  IconButton,
  LoadingIndicator,
  MenuButton,
} from "../../../../components";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  AddBrightHireToInterviewsMutation,
  InterviewAssistantEntryPoint,
  ScheduledInterviewListItemFragment,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import {
  ButtonAction,
  useScheduledInterviewLaunchButton,
} from "../../../hooks/useScheduledInterviewLaunchButton";

type LaunchButtonProps = {
  scheduledInterview: ScheduledInterviewListItemFragment;
  listPosition?: number;
  showUpdating?: "updating" | "updateFailed";
  interviewAssistantEntryPoint?: InterviewAssistantEntryPoint;
  onAddBrightHire?: (results: AddBrightHireToInterviewsMutation) => void;
} & ButtonProps;

const ScheduledInterviewLaunchButton: React.FC<LaunchButtonProps> = ({
  scheduledInterview,
  listPosition = 1,
  showUpdating = undefined,
  interviewAssistantEntryPoint,
  onAddBrightHire,
  size = "xs",
  w,
  mt,
  ...styles
}) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const currentUser = useCurrentUser();

  const { button, onStartInPerson, zoomUrl, cantLaunchInterviewReason } =
    useScheduledInterviewLaunchButton({
      scheduledInterview,
      interviewAssistantEntryPoint,
      showUpdating,
      onAddBrightHire,
      onAddBrightHireManuallyError: (message) => {
        errorToast(toast, message);
      },
    });

  const inPersonEnabled = currentUser.organization.allowInPersonMeetings;

  const trigger =
    button.variant === "disabled" ? (
      <Button
        isDisabled
        data-testid={`launch-zoom-button-${listPosition}`}
        {...styles}
      >
        {button.text}
      </Button>
    ) : (
      <Button
        variant={button.variant}
        onClick={button.execute}
        data-testid={`launch-zoom-button-${listPosition}`}
        data-zoom-url={button.action === ButtonAction.ZOOM ? zoomUrl : ""}
        {...styles}
      >
        {button.text}
      </Button>
    );

  return (
    <Flex alignItems="center">
      {![ButtonAction.LOADING, ButtonAction.NONE].includes(button.action) && (
        <>
          {cantLaunchInterviewReason && (
            <Tooltip
              id={`scheduled-${
                scheduledInterview?.candidate?.id ?? "unknown"
              }-launch-info`}
              placement="auto"
              label={<span>{cantLaunchInterviewReason}</span>}
            >
              <Icon as={MdInfo} mr={3} color="gray.400" />
            </Tooltip>
          )}
          <ButtonGroup size={size} isAttached w={w} mt={mt}>
            <Tooltip
              id={`scheduled-${
                scheduledInterview?.candidate?.id ?? "unknown"
              }-launch`}
              placement="auto"
              label={button.tooltip}
            >
              {trigger}
            </Tooltip>
            {inPersonEnabled && button.variant !== "disabled" && (
              <Menu placement="bottom-end">
                <MenuButton
                  data-tour-id={
                    listPosition === 1 ? "launch-upcoming-in-person" : undefined
                  }
                  variant={button.variant}
                  borderLeftWidth="1px"
                  as={IconButton}
                  aria-label="Start as in-person interview"
                  icon={<Icon as={HiChevronDown} boxSize="4" />}
                />
                <MenuList py="1">
                  <MenuItem
                    fontSize="sm"
                    onClick={() => {
                      onStartInPerson();
                      LogRocket.track("in-person-start-from-scheduled");
                      sendGAEvent(
                        "launch",
                        "in_person",
                        "From scheduled interview"
                      );
                    }}
                  >
                    Start as in-person interview
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </ButtonGroup>
        </>
      )}
      {button.action === ButtonAction.LOADING && <LoadingIndicator size="xs" />}
    </Flex>
  );
};

export default ScheduledInterviewLaunchButton;
