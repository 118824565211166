import { Flex, Heading, Text } from "@chakra-ui/react";
import React, { useState } from "react";

import {
  Button,
  CenteredCardLayout,
  errorToast,
  Link,
  LoadingIndicator,
  SuccessMessage,
  useToast,
} from "../../../components";
import {
  OnboardingStep,
  OnboardingStepStatus,
  useCurrentUserQuery,
  useCurrentUserZoomAppQuery,
  useUpdateCurrentUserOnboardingStepMutation,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import useSignOut from "../../hooks/useSignOut";
import { ZoomAppAutoOpen } from "./ZoomAppAutoOpen";

export const InstallZoomApp: React.FC = () => {
  const currentUser = useCurrentUser();
  const onSignOut = useSignOut({ currentUser });

  const { isZoomAppInstalled } = currentUser;
  const toast = useToast();

  const { refetch: refetchCurrentUser, loading: currentUserLoading } =
    useCurrentUserQuery({ notifyOnNetworkStatusChange: true });
  const [isPolling, setIsPolling] = useState(false);
  useCurrentUserZoomAppQuery({
    skip: !isPolling,
    pollInterval: 2000,
    onCompleted: (data) => {
      if (data.currentUser?.isZoomAppInstalled) {
        setIsPolling(false);
      }
    },
  });

  const [skipStep, { called: skipStepLoading }] =
    useUpdateCurrentUserOnboardingStepMutation({
      variables: {
        steps: [
          {
            step: OnboardingStep.InstallZoomApp,
            status: OnboardingStepStatus.Skipped,
          },
        ],
      },
      onError: () =>
        errorToast(
          toast,
          "An unknown error has occurred. Please contact support if you continue having issues."
        ),
    });

  const canShowAutoOpen =
    currentUser.organization.allowsZoomAppAutoOpen &&
    !currentUser.organization.requiresZoomAppAutoOpen;
  const [showAutoOpen, setShowAutoOpen] = useState(false);
  if (showAutoOpen) {
    return <ZoomAppAutoOpen onSignOut={onSignOut} />;
  }

  return (
    <CenteredCardLayout
      onSignOut={onSignOut}
      containerProps={{ alignItems: "center" }}
    >
      <Flex pt="8" flexDirection="column" alignItems="center" maxW="450">
        <Heading as="h1" fontSize="xl" fontWeight="normal" mb={4}>
          Set up BrightHire Interview Assistant Zoom app
        </Heading>
        {isPolling ? (
          <>
            <Text textAlign="center" fontSize="sm" mb="6">
              This page will update once you&apos;ve added the BrightHire
              Interview Assistant app to Zoom.
            </Text>
            <LoadingIndicator delay={0} />
          </>
        ) : isZoomAppInstalled ? (
          <SuccessMessage message="Zoom app installed" />
        ) : (
          <Text textAlign="center" fontSize="sm" mb="6">
            With the BrightHire Interview Assistant Zoom app, you can open the
            Interview Assistant directly from Zoom to take notes and view your
            interview guide.{" "}
            <Link
              href="https://help.brighthire.ai/en/collections/3357021-brighthire-interview-assistant-for-zoom-meetings"
              target="_blank"
            >
              Learn more.
            </Link>
          </Text>
        )}
        <Flex mt="8" alignSelf="stretch" gap={4}>
          {!isZoomAppInstalled || isPolling ? (
            <>
              <Button
                mr="auto"
                variant="outline"
                isLoading={skipStepLoading}
                onClick={() => {
                  skipStep().then(() => refetchCurrentUser());
                }}
              >
                Skip
              </Button>
              <Button
                isDisabled={skipStepLoading}
                onClick={() => {
                  window.open(`/zoomapp/install`, "_blank");
                  setIsPolling(true);
                }}
              >
                Install
              </Button>
            </>
          ) : (
            <Button
              m="auto"
              isLoading={currentUserLoading}
              onClick={() => {
                if (canShowAutoOpen) {
                  setShowAutoOpen(true);
                } else {
                  refetchCurrentUser();
                }
              }}
            >
              Next
            </Button>
          )}
        </Flex>
      </Flex>
    </CenteredCardLayout>
  );
};
